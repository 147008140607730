.footer {
  width: 100%;
  font-weight: 300;
  font-size: 1.4rem;
  color: #777;
  background-color: #fff;
  border-top: 1px solid #eee;
  text-align: right;
  position: absolute;
  bottom: 0;
  .container {
    position: relative;
    display: flex;
    align-content: center;
    flex-direction: column;
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }
  .footer-copyright {
    margin-bottom: 0;
    text-align: right;
    letter-spacing: 0;
  }
}
