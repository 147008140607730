.banner {
  &.banner-cat {
    position: relative;
    height: 220px;
    .card-wrapper {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: #fafafa;
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
        background-color: #fafafa;
        &.bvn-logo {
          object-fit: cover;
        }
      }
    }
  }
}