.form-input-field {
  background: 0 0;
  width: 100%;
  border: 1px solid rgba(0,0,0,.15);
  margin-bottom: 1rem;
  padding: 15px 20px;
  box-shadow: none;
  // font: 300 16px "Open Sans",sans-serif;
  -webkit-transition: ease-out .2s;
  transition: ease-out .2s;
  resize: none;
  &:focus {
    outline: none;
  }
}