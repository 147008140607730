.contact-list {
  max-width: 270px;
  list-style: none;
  padding-left: 0;
  li {
    position: relative;
    padding-left: 3rem;
    margin-bottom: 1.4rem;
  }
  i {
    display: inline-block;
    position: absolute;
    left: 0;
    top: .3rem;
    font-size: 2rem;
    line-height: 1;
    color: $secondary-color;
  }
}