.category-button {
  padding: 5px 15px;
  border: 1px solid #212529;
  border-radius: 30px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  transition: all .3s ease;
  i {
    font-size: 12px;
    padding-left: 7px;
  }
  &:hover {
    border-color: $secondary-color;
    color: white;
    background-color: $secondary-color;
  }
}


.category-buttons--wrapper {
  display: flex;
  flex-wrap: wrap;
}

.partner-wrapper {
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 100px;
  }
}


.product {
  position: relative;
  margin-bottom: 1rem;
  transition: box-shadow .35s ease;
  background-color: #fff;
  .product-image--wrapper {
    position: relative;
    background-color: white;
    height: 190px;
    display: flex;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .product-action {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    z-index: 10;
    transition: all .35s ease;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
  }
  .btn-product{
    color: $secondary-color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: 1;
    transition: all .35s ease;
    flex-grow: 1;
    flex-basis: 0;
    background-color: #333333;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
  }
  .product-subtitle {
    font-size: 1.2rem;
    text-align: center;
  }
  .product-title {
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    padding-bottom: 10px;
  }
  &:hover {
    box-shadow: 0 5px 20px rgba(0,0,0,0.05);
    .product-action {
      visibility: visible;
      opacity: 1;
      transform: translateY(-45px);
    }
  }
}