.intro-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.page-header {
  padding: 6.6rem 0 6.6rem;
  background-color: #ebebeb;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('../../../images/page-header-bg.jpg');
  text-align: center;
  h1 {
    font-size: 2.7em;
  }
}

.banner-display {
  position: relative;
  margin-bottom: 2rem;
  background-color: #ccc;
  height: 250px;
  .banner-inner {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(51,51,51,0.4);
      transition: all 0.35s ease;
    }
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;
      background-color: white;
    }
  }
  &:hover {
    .banner-inner {
      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
    .banner-subtitle {
      color: white;
    }
    .banner-title {
      color: white;
    }
    .banner-link {
      color: white;
      border-color: white;
    }
  }
  .banner-content {
    display: inline-block;
    position: absolute;
    padding-top: .4rem;
    left: 2rem;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
  }
}