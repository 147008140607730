.slide-img-wrapper {
  height: 530px;
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .slide-content {
    padding-left: 50px;
    z-index: 1;
  }
}

.slick-dots {
  bottom: 0;
  li {
    button {
      width: 12px;
      &::before {
        opacity: 0.75;
        content: "";
        color: #eee;
        border: 1px solid;
        background: transparent;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 6px;
        width: 6px;
        transition: all 0.3s ease;
        border: 1px solid #333;
      }
    }
    &.slick-active {
      button {
        width: 12px;
        &::before {
          background: $secondary-color;
          border-color: $secondary-color;
          height: 6px;
          width: 20px;
          border-radius: 30px;
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.custom-next-arrow {
  background: transparent;
  color: #333;
  right: 10px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  z-index: 1;
  transition: all 0.3s ease;

  &::before {
    content: "";
  }

  &:hover {
    color: $secondary-color;
    transition: all 0.3s ease;

  }
}

.custom-prev-arrow {
  background: transparent;
  color: #333;
  left: 10px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  z-index: 1;
  transition: all 0.3s ease;

  &::before {
    content: "";
  }
  &:hover {
    color: $secondary-color;
    transition: all 0.3s ease;
  }
}

.slide-category {
  font-weight: 300;
  letter-spacing: -.01em;
  margin-bottom: 0em;
  font-size: 20px;
}
.slide-title {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -.03em;
}

.btn-outline-white {
  color: #333;
  font-size: 14px;
  padding: 5px 15px;
  background-color: transparent;
  background-image: none;
  border-color: #333;
  box-shadow: none;
  border-radius: 0;
  white-space: normal;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid;
  &:hover {
    color: white;
    background: $secondary-color;
    border-color: $secondary-color;
  }
}

.references-slider {
  .slide-category {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .references-slider {
    .slide-img-wrapper {
      height: 250px;
    }
    .slick-list {
      margin-left: 0 -15px;
    }
    .slick-item {
      margin-left: 0 15px;
    }
    .custom-next-arrow {
      right: -30px;
    }
    .custom-prev-arrow {
      left: -30px;
    }
    .slide-category {
      padding: 17px;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .slide-img-wrapper {
    height: 300px;
  }
}